import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import Tile3 from "components/tile3"
import Ad from "components/ad"
import sofie from "../images/sofie_profil.png"

const IndexPage = ({ data }) => {
  const content = data.allContentYaml.nodes[0]
  const items = content.items

  return (
    <Layout>
      <SEO title="Forside" />

      <section className="hero is-primary">
        <div className="hero-body">
          <div className="container">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <div className="tile is-child notification is-primary">
                  <p className="title is-4">{content.title}</p>
                  <div dangerouslySetInnerHTML={{ __html: content.subtitle }}></div>
                </div>
              </div>
              <div className="tile is-3 is-vertical is-parent">
                <div className="tile is-child notification is-primary">
                  <figure className="image">
                    <img src={sofie} alt="profilbillede" />
                  </figure>
                  <p className="title is-4">Sofie Hjortø</p>
                  <p className="subtitle is-6">Overlæge i Gynækologi og Obstetrik</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <Ad />

          <Tile3 items={items} />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    allContentYaml {
      nodes {
        title
        subtitle
        items {
          title
          subtitle
          image
          link
        }
      }
    }
  }
`
